(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

jQuery(function ($) {
  'use strict'; // PreLoader

  $(window).on("load", function () {
    $(".pre-loader").fadeOut(function () {
      $(".loading-mask").fadeOut(800);
    });
  }); // Color Options

  $('.color-options-container .closer a').click(function () {
    if ($('.color-options-container').hasClass('open')) {
      $('.color-options-container').removeClass('open');
    } else {
      $('.color-options-container').addClass('open');
    }
  });
  $('.color-options-container .options .color-styles li a').click(function () {
    $('body').removeClass();
    $('body').addClass('b-' + $(this).attr('class'));
    $(this).attr('style');
    $('.color-options-container .options .color-styles li a .fa').removeClass('fa-check');
    $(this).find('.fa').addClass('fa-check');
  }); // Nav Bar Menu In Mobile

  $('.navbar-mobile .mobile-menu .treeview .treeview-toggle').on('click', function () {
    if ($(this).closest('.treeview').hasClass('open')) {
      $(this).closest('.treeview').removeClass('open').find('.treeview-menu').slideUp(800);
      $('.navbar-mobile .mobile-menu .treeview').removeClass('open').find('.treeview-menu').slideUp(800);
    } else {
      $('.navbar-mobile .mobile-menu .treeview').removeClass('open').find('.treeview-menu').slideUp(800);
      $(this).closest('.treeview').addClass('open').find('.treeview-menu').slideDown(800);
    }
  });
  $('.navbar-mobile .mobile-menu .treeview .treeview-sub-menu a').on('click', function () {
    if ($(this).closest('.treeview-sub-menu').hasClass('open')) {
      $(this).closest('.treeview-sub-menu').removeClass('open').find('.treeview-sub-menu-treeview-menu').slideUp(800);
      $('.navbar-mobile .mobile-menu .treeview-sub-menu').removeClass('open').find('.treeview-sub-menu-treeview-menu').slideUp(800);
    } else {
      $('.navbar-mobile .mobile-menu .treeview-sub-menu').removeClass('open').find('.treeview-sub-menu-treeview-menu').slideUp(800);
      $(this).closest('.treeview-sub-menu').addClass('open').find('.treeview-sub-menu-treeview-menu').slideDown(800);
    }
  });
  $('.navbar-mobile-show a').on('click', function () {
    $('.navbar-mobile').addClass('open');
  });
  $('.navbar-mobile .close-mobile').on('click', function () {
    $('.navbar-mobile').removeClass('open');
  }); // init Isotope

  if ($('.isotope_4').length > 0) {
    var $grid = $('.isotope_4').isotope({
      itemSelector: '.isotope_4 div[class*=col-sm-]'
    });
    $('.portfolio-filters li a').on('click', function () {
      var filterValue = $(this).attr('data-filter');
      $grid.isotope({
        filter: filterValue
      });
    });
  }

  if ($('.isotope_3').length > 0) {
    var $grid = $('.isotope_3').isotope({
      // options
      itemSelector: '.isotope_3 div[class*=col-sm-]'
    }); // filter items on button click

    $('.portfolio-filters li a').on('click', function () {
      var filterValue = $(this).attr('data-filter');
      $grid.isotope({
        filter: filterValue
      });
    });
  }

  if ($('.isotope_2').length > 0) {
    var $grid = $('.isotope_2').isotope({
      // options
      itemSelector: '.isotope_2 div[class*=col-sm-]'
    }); // filter items on button click

    $('.portfolio-filters li a').on('click', function () {
      var filterValue = $(this).attr('data-filter');
      $grid.isotope({
        filter: filterValue
      });
    });
  } // LightBox Trigger


  $('.video-components a').on('click', function (e) {
    e.preventDefault();
    $(this).ekkoLightbox();
  });
  $('.portfolio .project a[data-toggle="lightbox"]').on('click', function (e) {
    e.preventDefault();
    $(this).ekkoLightbox();
  });
  $('.single-project .portfolio-single-image a[data-toggle="lightbox"]').on('click', function (e) {
    e.preventDefault();
    $(this).ekkoLightbox();
  });
  $('.single-project .carousel-inner .item a[data-toggle="lightbox"]').on('click', function (e) {
    e.preventDefault();
    $(this).ekkoLightbox();
  }); // Add To Slider Control A Background Image

  $('.slider-content .carousel').on('slid.bs.carousel', function () {
    if ($('.slider-content .item.active').prev().length === 0) {
      // First Item
      $('.slider-content .carousel-control.left .fa').css('background-image', 'url("' + $('.slider-content .item').last().find('img').attr('src') + '")');
    } else {
      // There Is Prev
      $('.slider-content .carousel-control.left .fa').css('background-image', 'url("' + $('.slider-content .item.active').prev().find('img').attr('src') + '")');
    }

    if ($('.slider-content .item.active').next().length === 0) {
      // Last Item
      $('.slider-content .carousel-control.right .fa').css('background-image', 'url("' + $('.slider-content .item').first().find('img').attr('src') + '")');
    } else {
      // There Is Next
      $('.slider-content .carousel-control.right .fa').css('background-image', 'url("' + $('.slider-content .item.active').next().find('img').attr('src') + '")');
    }
  });
  $('.slider-content .carousel-control.left > .fa').css('background-image', 'url("' + $('.slider-content .item').last().find('img').attr('src') + '")');
  $('.slider-content .carousel-control.right > .fa').css('background-image', 'url("' + $('.slider-content .item.active').next().find('img').attr('src') + '")'); // Down Button Click

  $('.smoothscroll').on('click', function () {
    var $scrollWay = $(this).closest('.site').next().attr('class');
    $('html, body').animate({
      scrollTop: $('.' + $scrollWay).offset().top - 80
    }, 1000);
  }); // CountUp Plugin Trigger

  if ($('.statics .static h5').length > 0) {
    $('.statics .static h5').counterUp({
      delay: 10,
      time: 1000
    });
  } // Owl Carousel Plugin Trigger


  if ($('.testimonials.owl-carousel').length > 0) {
    $('.testimonials.owl-carousel').owlCarousel({
      items: 1,
      itemsDesktop: [1199, 1],
      itemsDesktopSmall: [979, 1],
      itemsTablet: [600, 1],
      itemsMobile: [479, 1],
      slideSpeed: 800,
      mouseDrag: false,
      pagination: false,
      navigation: true,
      navigationText: ["<span class='fa fa-angle-left'></span>", "<span class='fa fa-angle-right'></span>"],
      autoPlay: false
    });
  }

  if ($('.clients.owl-carousel').length > 0) {
    $('.clients.owl-carousel').owlCarousel({
      items: 6,
      slideSpeed: 800,
      mouseDrag: true,
      pagination: false,
      navigation: false,
      autoPlay: true
    });
  }

  if ($('.blog-posts .owl-carousel').length > 0) {
    $('.blog-posts .owl-carousel').owlCarousel({
      items: 3,
      itemsDesktop: [1199, 3],
      itemsDesktopSmall: [979, 2],
      itemsTablet: [600, 1],
      itemsMobile: [479, 1],
      slideSpeed: 800,
      mouseDrag: true,
      pagination: false,
      navigation: true,
      navigationText: ["<span class='fa fa-angle-left'></span>", "<span class='fa fa-angle-right'></span>"],
      autoPlay: true
    });
  }

  if ($('.blog .owl-carousel').length > 0) {
    $('.blog .owl-carousel').owlCarousel({
      items: 1,
      itemsDesktop: [1199, 1],
      itemsDesktopSmall: [979, 1],
      itemsTablet: [600, 1],
      itemsMobile: [479, 1],
      slideSpeed: 800,
      mouseDrag: true,
      pagination: false,
      navigation: true,
      navigationText: ["<span class='fa fa-angle-left'></span>", "<span class='fa fa-angle-right'></span>"],
      autoPlay: true
    });
  }

  if ($('.testimonials-layout-2 .owl-carousel').length > 0) {
    $('.testimonials-layout-2 .owl-carousel').owlCarousel({
      items: 1,
      itemsDesktop: [1199, 1],
      itemsDesktopSmall: [979, 1],
      itemsTablet: [600, 1],
      itemsMobile: [479, 1],
      slideSpeed: 800,
      mouseDrag: true,
      pagination: false,
      navigation: true,
      navigationText: ["<span class='fa fa-angle-left'></span>", "<span class='fa fa-angle-right'></span>"],
      autoPlay: true
    });
  }

  if ($('.sponsors-carousel .owl-carousel').length > 0) {
    $('.sponsors-carousel .owl-carousel').owlCarousel({
      items: 6,
      itemsDesktop: [1199, 6],
      itemsDesktopSmall: [979, 4],
      itemsTablet: [600, 3],
      itemsMobile: [479, 2],
      slideSpeed: 800,
      mouseDrag: true,
      pagination: false,
      navigation: false,
      autoPlay: true
    });
  } // Add Angle Right Icon To Submenu


  $('.nav .dropdown-menu li.dropdown-submenu a.dropdown-toggle').each(function () {
    $(this).append('<i class="fa fa-angle-right"></i>');
  }); // Accordions

  $('.accordions .panel-title a i.fa-plus').on('click', function () {
    $(this).removeClass('fa-plus').addClass('fa-minus');
  });
  $('.accordions .panel-title a i.fa-minus').on('click', function () {
    $(this).removeClass('fa-minus').addClass('fa-plus');
  }); // Add Class Active To Portfolio Filter

  $('.portfolio .portfolio-filters li a').on('click', function () {
    $('.portfolio .portfolio-filters li a.active').removeClass('active');
    $(this).addClass('active');
  }); // Navbar Add Class Active To links
  // Add Class Fixed To Navbar

  $(window).scroll(function () {
    if ($(this).scrollTop() >= 200) {
      $('.navbar').addClass('navbar-fixed-top');
    } else {
      $('.navbar').removeClass('navbar-fixed-top');
    }
  }); // Search Form Show Up

  $('.navbar .navbar-right li.search-link a').on('click', function () {
    if ($(this).hasClass('show')) {
      $(this).removeClass('show');
      $('.search-link .search-form').slideDown(500);
    } else {
      $('.search-link .search-form').slideUp(500);
      $(this).addClass('show');
    }
  });
  $('.navbar .search-link-mobile a').on('click', function () {
    if ($(this).hasClass('show')) {
      $(this).removeClass('show');
      $('.search-link-mobile .search-form').slideDown(500);
    } else {
      $('.search-link-mobile .search-form').slideUp(500);
      $(this).addClass('show');
    }
  }); // Show And Hide Contact Form

  $('.mail-help a.mail-envelope').on('click', function () {
    $('.mail-help').addClass('open');
  });
  $('.mail-help a.mail-close').on('click', function () {
    $('.mail-help').removeClass('open');
  }); // Landing Pages Demos Hover

  $('.demo-box').on('mouseover', function () {
    $(this).find('img').css('margin-top', '-' + ($(this).find('img').innerHeight() - 414) + 'px');
  });
  $('.demo-box').on('mouseout', function () {
    $(this).find('img').css('margin-top', '0');
  }); // Landing Page Down Button Click

  $('.landing-page .header .navbar li a').on('click', function () {
    $('html, body').animate({
      scrollTop: $('.' + $(this).data('click')).offset().top - 80
    }, 1000);
  });
});

},{}]},{},[1]);
